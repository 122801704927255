<template>
  <v-card class="elevation-10" v-if="announcements.length">
    <div class="pa-4 pa-sm-6">
      <h3 class="font-weight-bold mb-4 mb-sm-6">Advisories</h3>
      <div class="rounded-lg overflow-hidden">
        <v-carousel
          delimiter-icon="mdi-circle"
          show-arrows-on-hover
          :show-arrows="announcements.length > 1 ? true : false"
          height="auto"
          cycle
        >
          <v-carousel-item
            v-for="(item, i) in announcements"
            :key="i"
            style="background: transparent"
          >
            <v-sheet style="background: transparent !important" height="100%">
              <v-row class="fill-height" no-gutters>
                <img
                  :src="getImgSrc(item.image)"
                  alt="..."
                  style="display: block; width: 100%; height: auto"
                />
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </div>
    </div>
  </v-card>
</template>

<script>
import ApiRequestGet from "@/helpers/api_request_get.js";

export default {
  data: () => ({
    announcements: [],
  }),
  mounted() {
    this.getAnnouncements();
  },

  name: "Announcements",

  methods: {
    async getAnnouncements() {
      const res = await ApiRequestGet("", "home/announcements");

      if (res.status == 200) {
        this.announcements = res.data.records;
      }
    },
    getImgSrc(filename) {
      return require("@/assets/img/announcements/" + filename);
    },
  },
};
</script>
