<template>
  <div>
    <v-card class="elevation-10" v-if="!gamesLoading">
      <div>
        <div class="pa-4 pa-sm-6">
          <h3 class="font-weight-bold" v-if="games && games.length">
            Today's Games
          </h3>
          <div class="grey--text mt-2" v-if="games && games.length">
            Signup or login to your account to start playing.
          </div>
          <div class="d-flex flex-column bs-gap-3 text-center grey--text mt-2" v-else>
            <v-icon color="grey">mdi-clipboard-off-outline</v-icon>
            There's currently no scheduled games today.
          </div>
        </div>
        <v-simple-table>
          <tbody>
            <tr v-for="item in games" :key="item.id">
              <td>
                {{ formatGameSchedule(item.schedule) }}
              </td>
              <td>
                <div class="d-flex align-center bs-gap-2">
                  <div class="d-inline d-md-none">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar size="24" tile v-bind="attrs" v-on="on">
                          <v-img :src="item.league_logo"> </v-img>
                        </v-avatar>
                      </template>
                      <span>{{ item.league }}</span>
                    </v-tooltip>
                  </div>
                  <span class="d-none d-md-inline">{{ item.league }}</span>
                </div>
              </td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex align-center bs-gap-2" v-bind="attrs" v-on="on">
                      <v-img
                        :src="item.team_a_logo"
                        max-width="24"
                        max-height="24"
                      ></v-img>

                      <span>VS</span>

                      <v-img
                        :src="item.team_b_logo"
                        max-width="24"
                        max-height="24"
                      ></v-img>
                    </div>
                  </template>
                  <span>{{ item.team_vs }}</span>
                </v-tooltip>
              </td>
              <td>
                <div class="d-flex justify-end" v-if="!$vuetify.breakpoint.smAndUp">
                  <a
                    v-if="item.game_status_id === null"
                    href="#"
                    class="font-weight-bold"
                    style="text-decoration: none"
                    role="button"
                    @click.prevent="loginDialog = true"
                  >
                    Play Now
                  </a>
                  <div v-else-if="item.game_status_id == 1" class="green--text">
                    1st Quarter
                  </div>
                  <div v-else-if="item.game_status_id == 2" class="green--text">
                    2nd Quarter
                  </div>
                  <div v-else-if="item.game_status_id == 3" class="green--text">
                    3rd Quarter
                  </div>
                  <div v-else-if="item.game_status_id == 4" class="green--text">
                    4th Quarter
                  </div>
                  <div v-else-if="item.game_status_id == 5" class="red--text">Ended</div>
                </div>
                <div class="d-flex justify-end" v-else>
                  <a
                    v-if="item.game_status_id === null"
                    href="#"
                    class="font-weight-bold"
                    style="text-decoration: none"
                    role="button"
                    @click.prevent="loginDialog = true"
                  >
                    Play Now
                  </a>
                  <div v-else-if="item.game_status_id == 1" class="green--text">
                    1st Q
                  </div>
                  <div v-else-if="item.game_status_id == 2" class="green--text">
                    2nd Q
                  </div>
                  <div v-else-if="item.game_status_id == 3" class="green--text">
                    3rd Q
                  </div>
                  <div v-else-if="item.game_status_id == 4" class="green--text">
                    4th Q
                  </div>
                  <div v-else-if="item.game_status_id == 5" class="red--text">Ended</div>
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </v-card>

    <LoginDialog v-if="loginDialog" @setDialogs="setDialogs" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import formatDate from "@/helpers/formatDate";
import LoginDialog from "@/components/LoginDialog";
import "@/assets/home.css";

export default {
  components: { LoginDialog },

  data: () => ({
    itemsPerpage: -1,
    loginDialog: false,
    sortBy: "schedule",
    sortDesc: false,
    headers: [
      { text: "Schedule", value: "schedule", class: "text-uppercase" },
      { text: "League", value: "league", class: "text-uppercase" },
      { text: "Game", value: "game", class: "text-uppercase" },
      {
        text: "Action",
        value: "action",
        sortable: false,
        class: "text-uppercase",
      },
    ],
  }),

  mounted() {
    this.doLoadGames();
  },

  computed: {
    ...mapState("todays_games", ["games", "gamesLoading", "error"]),

    gamesLessThan5() {
      const divider = this.games.length / 2;
      return this.games.filter((game, i) => {
        return i < divider;
      });
    },

    gamesGreaterThan5() {
      const divider = this.games.length / 2;
      return this.games.filter((_, i) => {
        return i >= divider;
      });
    },
  },

  methods: {
    ...mapActions("todays_games", ["getGames"]),

    async doLoadGames() {
      await this.getGames();
    },

    setDialogs() {
      this.loginDialog = false;
    },

    formatGameSchedule(val) {
      return formatDate(val, "time");
    },

    zeroPad(num, places) {
      var zero = places - num.toString().length + 1;
      return Array(+(zero > 0 && zero)).join("0") + num;
    },
  },
};
</script>
