<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="400px"
    >
      <v-card class="bs-shadow-none bs-position-relative">
        <div class="bs-text-center pa-4 pa-sm-6 bs-p-lg-5">
          <div class="rounded-lg bs-d-inline-block mb-4">
            <img :src="getGameLogo(game.logo)" alt="..." class="bs-d-block" />
          </div>
          <div class="mb-4 mb-sm-6">
            <h3 class="bs-fw-bold mb-1">{{ game.game }}</h3>
            <p class="mb-0">{{ game.description }}</p>
          </div>
          <v-btn
            class="primary py-5 px-4"
            depressed
            style="min-width: unset"
            @click="loginDialog = true"
            :disabled="game.is_active == 'Inactive'"
          >
            {{ game.is_active == "Active" ? "Play" : "Coming Soon" }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <LoginDialog v-if="loginDialog" @setDialogs="setDialogs" />
  </div>
</template>

<style scoped>
img {
  width: auto;
  height: 125px;
}
</style>

<script>
import LoginDialog from "@/components/LoginDialog";

export default {
  components: {
    LoginDialog,
  },

  data: () => ({
    loginDialog: false,
  }),

  props: {
    game: {
      type: Object,
    },
  },

  computed: {
    dialog: {
      get: function () {
        return true;
      },
      set: function () {
        this.closeDialog();
      },
    },
  },

  methods: {
    setDialogs() {
      this.loginDialog = false;
    },
    closeDialog() {
      this.$emit("setCloseGameDialog");
    },
    getGameLogo(logo) {
      return require("@/assets/images/games/" + logo);
    },
  },
};
</script>
