<template>
  <v-card class="carousel-card elevation-10" style="border: none !important">
    <v-carousel
      :show-arrows="slides.length > 1 ? true : false"
      height="auto"
      delimiter-icon="mdi-circle"
    >
      <v-carousel-item v-for="(slide, index) in slides" :key="index">
        <img
          :src="getImgSrc(slide)"
          alt="..."
          style="display: block; width: 100%; height: auto"
        />
      </v-carousel-item>
    </v-carousel>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    slides: ["slide-1.jpg", "web-landscape-2.jpg"],
  }),

  methods: {
    getImgSrc(filename) {
      return require("@/assets/carousel/" + filename);
    },
  },
};
</script>
