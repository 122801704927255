<template>
  <div class="winners">
    <div class="d-flex flex-column bs-gap-3" v-if="!recordsLoading">
      <v-card v-if="records.records && records.records.length != 0" class="elevation-10">
        <div class="pa-4 pa-sm-6">
          <h3 class="font-weight-bold d-flex gap-4 justify-content-between w-100 mb-2">
            Top Winners
          </h3>
          <div class="grey--text">Congratulations to our lucky winners.</div>
        </div>
        <v-simple-table>
          <tbody>
            <tr v-for="(item, i) in records.records" :key="i">
              <td style="width: 20%">
                <div class="d-flex align-center bs-gap-3">
                  <v-icon :color="setTrophyColor(i)"> {{ setTrophy(i) }} </v-icon>
                  {{ item.username }}
                </div>
              </td>
              <td style="width: 15%">
                <div class="d-flex align-center bs-gap-2">
                  <div class="d-inline d-md-none">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar size="24" tile v-bind="attrs" v-on="on">
                          <v-img :src="item.league_logo"> </v-img>
                        </v-avatar>
                      </template>
                      <span>{{ item.league }}</span>
                    </v-tooltip>
                  </div>
                  <span class="d-none d-md-inline">{{ item.league }}</span>
                </div>
              </td>
              <td style="width: 20%">
                <div class="text-right">
                  <span>{{ moneyFormat(parseFloat(item.bet_amount)) }}</span>
                </div>
              </td>
              <td style="width: 20%">
                <div class="text-right">
                  <span class="font-weight-bold">{{
                    moneyFormat(parseFloat(item.prize))
                  }}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>

      <v-card
        v-if="records.records1 && records.records1.length != 0"
        class="elevation-10"
      >
        <div class="pa-4 pa-sm-6">
          <h3 class="font-weight-bold d-flex gap-4 justify-content-between w-100 mb-0">
            Today's Winners
          </h3>
        </div>
        <div style="overflow-y: auto; max-height: 288px">
          <v-simple-table>
            <tbody>
              <tr v-for="(item, i) in records.records1" :key="i">
                <td style="width: 20%">
                  <div class="d-flex align-center bs-gap-3">
                    <v-icon :color="setTrophyColor(i)"> {{ setTrophy(i) }} </v-icon>
                    {{ item.username }}
                  </div>
                </td>
                <td style="width: 15%">
                  <div class="d-flex align-center bs-gap-2">
                    <div class="d-inline d-md-none">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-avatar size="24" tile v-bind="attrs" v-on="on">
                            <v-img :src="item.league_logo"> </v-img>
                          </v-avatar>
                        </template>
                        <span>{{ item.league }}</span>
                      </v-tooltip>
                    </div>
                    <span class="d-none d-md-inline">{{ item.league }}</span>
                  </div>
                </td>
                <td style="width: 20%">
                  <div class="text-right">
                    <span>{{ moneyFormat(parseFloat(item.bet_amount)) }}</span>
                  </div>
                </td>
                <td style="width: 20%">
                  <div class="text-right">
                    <span class="font-weight-bold">{{
                      moneyFormat(parseFloat(item.prize))
                    }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import formatDate from "@/helpers/formatDate";
import "@/assets/home.css";

export default {
  data: () => ({
    itemsPerpage: -1,
    sortBy: "schedule",
    sortDesc: false,
    headers: [
      { text: "Player", value: "player", class: "text-uppercase" },
      { text: "Schedule", value: "schedule", class: "text-uppercase" },
      { text: "League", value: "league", class: "text-uppercase" },
      { text: "Game", value: "game", class: "text-uppercase" },
      { text: "Type", value: "type", class: "text-uppercase" },
      {
        text: "Token",
        value: "bet_amount",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Prize",
        value: "prize",
        class: "text-uppercase",
        align: "right",
      },
    ],
    winners_view: "today",
  }),

  mounted() {
    this.doLoadRecords();
  },

  computed: {
    ...mapState("winners", ["records", "recordsLoading", "error"]),
  },

  methods: {
    ...mapActions("winners", ["getRecords"]),

    async doLoadRecords() {
      await this.getRecords();
    },

    formatGameSchedule(val) {
      return formatDate(val, "slashed-date");
    },
    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    setTrophy(value) {
      var icon = "";

      if (value == 0 || value == 1 || value == 2) {
        icon = "mdi-trophy";
      } else {
        icon = "mdi-trophy-outline";
      }

      return icon;
    },
    setTrophyColor(value) {
      var color = "";

      switch (value) {
        case 0:
          {
            color = "orange";
          }
          break;
        case 1:
          {
            color = "grey";
          }
          break;
        case 2:
          {
            color = "brown";
          }
          break;
        default:
          color = "grey";
      }

      return color;
    },
  },
};
</script>
