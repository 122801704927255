<template>
  <div class="games">
    <v-card v-if="!gamesLoading" class="elevation-10">
      <div class="pa-4 pa-sm-6">
        <h3 class="schedule font-weight-bold mb-2">GameX Games</h3>
        <div class="grey--text mb-4 mb-sm-6">
          Play GameX Sports games and win.
        </div>
        <div v-if="$vuetify.breakpoint.mdAndUp" class="bs-row bs-g-3">
          <div v-for="(game, i) in games" :key="i" class="bs-col-6 bs-col-md-3">
            <div class="game-container rounded-lg light" style="height: 100%">
              <div
                class="game-container-overlay rounded-lg"
                v-if="game.description !== null"
              >
                <div
                  class="game-info d-flex flex-column justify-space-between bs-gap-3 bs-h-100 pa-4"
                >
                  <div class="game-description" style="font-size: 12px">
                    {{ game.description }}
                  </div>
                  <div>
                    <div class="mb-1">{{ game.game }}</div>
                    <a
                      href="#"
                      @click.prevent="setLoginDialog(game.route)"
                      v-if="game.is_active == 'Active'"
                      class="font-weight-bold"
                    >
                      Play Now
                    </a>
                    <div v-else class="grey--text">Coming Soon</div>
                  </div>
                </div>
              </div>
              <v-img
                :src="getGameLogo(game.logo)"
                alt="..."
                style="width: 100%; height: auto"
              >
              </v-img>
              <div class="px-4 pb-4">
                <div class="mb-1">{{ game.game }}</div>
                <a
                  href="#"
                  @click.prevent="setLoginDialog(game.route)"
                  v-if="game.is_active == 'Active'"
                  class="font-weight-bold"
                >
                  Play Now
                </a>
                <div v-else class="grey--text">Coming Soon</div>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="bs-row bs-g-3">
          <div v-for="(game, i) in games" :key="i" class="bs-col-4 bs-col-md-3">
            <div class="game-container rounded-lg light">
              <v-img
                :src="getGameLogo(game.logo)"
                alt="..."
                style="width: 100%; height: auto"
              >
              </v-img>
              <div
                class="game-container-overlay d-flex align-center justify-center rounded-lg"
                v-if="game.description !== null"
              >
                <v-btn
                  @click="setLoginDialog(game.route)"
                  small
                  depressed
                  color="primary"
                >
                  Play
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>

    <GameDetailsDialog
      v-if="gameDetailsDialog"
      :game="gameData"
      @setCloseGameDialog="setCloseGameDialog"
    />
    <LoginDialog v-if="loginDialog" :route="route" @setDialogs="setDialogs" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import LoginDialog from "@/components/LoginDialog.vue";
import GameDetailsDialog from "@/components/GameDetailsDialog.vue";

export default {
  components: { LoginDialog, GameDetailsDialog },

  data: () => ({
    loginDialog: false,
    gameDetailsDialog: false,
    gameData: [],
    gameTitle: "",
    gameDescription: "",
    gameLogo: "",
    route: "",
  }),

  mounted() {
    this.doLoadGames();
  },

  computed: {
    ...mapState("home_games", ["games", "gamesLoading", "error"]),
  },

  methods: {
    ...mapActions("home_games", ["getGames"]),

    async doLoadGames() {
      await this.getGames();
    },

    getGameLogo(logo) {
      return require("@/assets/img/games/" + logo);
    },

    setDialogs() {
      this.loginDialog = false;
    },

    setCloseGameDialog() {
      this.gameDetailsDialog = false;
    },

    setLoginDialog(route) {
      this.loginDialog = true;
      this.route = route;
    },
  },
};
</script>
