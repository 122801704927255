<template>
  <v-card class="testimonials elevation-10 bs-overflow-hidden">
    <div class="pa-4 pa-sm-6">
      <h3 class="font-weight-bold mb-4 mb-sm-6">Testimonials</h3>
      <div class="overflow-hidden rounded-lg">
        <v-carousel delimiter-icon="mdi-circle" show-arrows-on-hover height="auto" cycle>
          <v-carousel-item>
            <v-sheet>
              <v-row class="fill-height" align="center" justify="center" no-gutters>
                <img
                  src="@/assets/img/testimonials/viber_image_2023-04-29_15-21-35-182.jpg"
                />
              </v-row>
            </v-sheet>
          </v-carousel-item>
          <v-carousel-item>
            <v-sheet>
              <v-row class="fill-height" align="center" justify="center" no-gutters>
                <img
                  src="@/assets/img/testimonials/viber_image_2023-04-29_15-21-35-827.jpg"
                />
              </v-row>
            </v-sheet>
          </v-carousel-item>
          <v-carousel-item>
            <v-sheet>
              <v-row class="fill-height" align="center" justify="center" no-gutters>
                <img
                  src="@/assets/img/testimonials/viber_image_2023-04-29_15-21-35-821.webp"
                />
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "Testimonials",
};
</script>

<style scoped>
.testimonials img {
  width: 100%;
  height: auto;
}
</style>
