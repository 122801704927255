<template>
  <div class="upcoming-games" v-if="records.length">
    <v-card class="elevation-10">
      <div class="pa-4 pa-sm-6">
        <h3 class="font-weight-bold mb-1">{{ title }}</h3>
        <div class="grey--text mt-2">
          Register or login to your account to start betting.
        </div>
      </div>
      <v-simple-table>
        <tbody>
          <tr v-for="item in records" :key="item.id">
            <td>
              {{ formatGameSchedule(item.schedule) }}
            </td>
            <td>
              <div class="d-flex align-center bs-gap-2">
                <div class="d-inline d-md-none">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar size="24" tile v-bind="attrs" v-on="on">
                        <v-img :src="item.league_logo"> </v-img>
                      </v-avatar>
                    </template>
                    <span>{{ item.league }}</span>
                  </v-tooltip>
                </div>
                <span class="d-none d-md-inline">{{ item.league }}</span>
              </div>
            </td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="d-flex align-center bs-gap-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-img
                      :src="item.team_a_logo"
                      max-width="24"
                      max-height="24"
                    ></v-img>

                    <span>VS</span>

                    <v-img
                      :src="item.team_b_logo"
                      max-width="24"
                      max-height="24"
                    ></v-img>
                  </div>
                </template>
                <span>{{ item.team_vs }}</span>
              </v-tooltip>
            </td>
            <td>
              <div
                class="d-flex justify-end"
                v-if="!$vuetify.breakpoint.smAndUp"
              >
                <a
                  v-if="item.game_status_id === null"
                  href="#"
                  class="font-weight-bold"
                  style="text-decoration: none"
                  role="button"
                  @click.prevent="loginDialog = true"
                >
                  Play Now
                </a>
                <div v-else-if="item.game_status_id == 1" class="green--text">
                  1st Quarter
                </div>
                <div v-else-if="item.game_status_id == 2" class="green--text">
                  2nd Quarter
                </div>
                <div v-else-if="item.game_status_id == 3" class="green--text">
                  3rd Quarter
                </div>
                <div v-else-if="item.game_status_id == 4" class="green--text">
                  4th Quarter
                </div>
                <div v-else-if="item.game_status_id == 5" class="red--text">
                  Ended
                </div>
              </div>
              <div class="d-flex justify-end" v-else>
                <a
                  v-if="item.game_status_id === null"
                  href="#"
                  class="font-weight-bold"
                  style="text-decoration: none"
                  role="button"
                  @click.prevent="loginDialog = true"
                >
                  Play Now
                </a>
                <div v-else-if="item.game_status_id == 1" class="green--text">
                  1st Q
                </div>
                <div v-else-if="item.game_status_id == 2" class="green--text">
                  2nd Q
                </div>
                <div v-else-if="item.game_status_id == 3" class="green--text">
                  3rd Q
                </div>
                <div v-else-if="item.game_status_id == 4" class="green--text">
                  4th Q
                </div>
                <div v-else-if="item.game_status_id == 5" class="red--text">
                  Ended
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>

    <LoginDialog v-if="loginDialog" @setDialogs="setDialogs" />
  </div>
</template>

<script>
import ApiRequestGet from "@/helpers/api_request_get.js";
import formatDate from "@/helpers/formatDate";
import LoginDialog from "@/components/LoginDialog";

export default {
  components: { LoginDialog },
  data: () => ({
    title: "Today's FIBA Games",
    loginDialog: false,
    records: [],
    recordsLoading: false,
  }),
  mounted() {
    this.getFiba();
  },
  methods: {
    async getFiba() {
      this.recordsLoading = true;

      const res = await ApiRequestGet("", "home/fiba_games");

      if (res.status == 200) {
        this.records = res.data.records;
      } else {
        //
      }

      this.recordsLoading = false;
    },
    setDialogs() {
      this.loginDialog = false;
    },
    formatGameSchedule(val) {
      return formatDate(val, "time");
    },
  },
};
</script>
